<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col cols="10">
          <base-subheading class="success--text" title="Muchas gracias!" />

          <base-subheading
            space="8"
            class="text-h6"
            title="Su reserva se ha realizado correctamente. Nos vemos pronto!"
          />

          <v-row justify="center">
            <v-col class="d-flex justify-center">
              <base-img
                :aspect-ratio="1"
                :src="require('@/assets/icons/check-green.gif')"
                class="rounded-lg"
                max-width="12rem"
              />
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="11">
                  <base-btn block to="/menu">Ver la carta</base-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "ThankYouReserveSection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "100vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
